@import url('https://fonts.googleapis.com/css?family=Abel|Raleway|Raleway+Dot|Asap|Barlow|Glegoo|Julius+Sans+One|Lato|Muli|Poiret+One|Sulphur+Point|Turret+Road&display=swap');
@import url('https://fonts.googleapis.com/css?family=Abel|Raleway|Raleway+Dot|Asap|Barlow|Glegoo|Julius+Sans+One|Lato|Muli|Poiret+One|Sulphur+Point|Turret+Road&display=swap');
@import url('https://fonts.googleapis.com/css?family=Abel|Raleway|Raleway+Dot|Asap|Barlow|Glegoo|Julius+Sans+One|Lato|Muli|Poiret+One|Sulphur+Point|Turret+Road&display=swap');

.cardDisclaimer {
  background: rgba(255, 255, 255, 0.1);
  color: white;
  /* border: 1px solid white; */
  border-radius: 10px;
  text-align: right;
  margin: 3em;
  width: 50%;
  margin: 3% 25%;
  line-height: 3em;
  height: 50px;
  vertical-align: middle;
  text-align: center;
}
.mainRow {
  font-family: 'Sulphur Point', sans-serif;
  margin-top: 50px;
}
.cont {
  padding: 0 3%;
}
.cardCol {
  padding: 0.5%;
}
.cardMain {
  background: rgba(255, 255, 255, 0.1);
  color: white;
  border-color: white;
  text-align: center;
  margin: 0 1em;
  display: block;
  padding: 10px;
}
.cardMain h2,
.cardMain footer,
.cardMain img {
  text-align: center;
}
.cardMain img {
  display: block;
  margin: 0 auto;
}
.cardMain:hover {
  transition: all 0.2s ease-out;
  top: -4px;
  background: rgba(255, 255, 255, 0.15);
  box-shadow: 0px 4px 35px rgb(255, 255, 255);
}
.portImg {
  padding: 5px;
  display: inline-block;
  min-width: 230px;
  min-height: 95px;
  width: auto;
  height: auto;
  border-radius: 10px;
}
