.loadingHeader {
  background-color: #000000;
  background-image: url('https://nathanbirch.one/images/background.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: white;
  text-align: center;
  align-items: center;
  justify-content: center;
  z-index: 1;
}
