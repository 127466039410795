#softSkill-tooltip > .tooltip-inner {
  background-color: #2e55c1;
  color: aliceblue;
  border: 2px solid #ff3f8e;
}

#softSkill-tooltip > .tooltip-arrow {
  border-top: 5px solid #2e55c1;
}

.tooltip.show {
  opacity: 1;
}
