body,
html {
  margin: 0;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  overflow: scroll;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  background: #ff0000;
}

.particle {
  position: fixed !important;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.resumeButton {
  border-color: #2e55c1;
}

.resumeButton:hover {
  border-color: #2e55c1;
  background: #6666666b;
  color: aliceblue;
}

.aboutMain {
  min-height: 95vh;
}

.aboutHeader {
  background-color: #090909;
}

.aboutFooter {
  min-height: 5vh;
}

.both {
  text-align: center;
  font-family: 'Roboto Mono', monospace;
  color: aliceblue;
  margin: 0 auto;
}

.both_icon {
  font-size: 10vw;
  color: aliceblue;
}

.both_label {
  font-size: 20px;
  color: aliceblue;
}

.hov:hover .both,
.hov:hover .both_icon,
.hov:hover .both_label {
  color: #ff3f8e !important;
  cursor: pointer;
  text-decoration: none;
}

@media screen and (min-width: 1000px) {
  .both_icon {
    font-size: 100px;
  }
}
