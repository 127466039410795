@import url('https://fonts.googleapis.com/css?family=Abel|Raleway|Raleway+Dot|Asap|Barlow|Glegoo|Julius+Sans+One|Lato|Muli|Poiret+One|Sulphur+Point|Turret+Road&display=swap');
@import url('https://fonts.googleapis.com/css?family=Indie+Flower|Lobster+Two|Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css?family=Abel|Asap|Raleway+Dots|Roboto+Mono|Raleway|Barlow|Glegoo|Julius+Sans+One|Lato|Muli|Poiret+One|Sulphur+Point|Turret+Road&display=swap');
.clcMain {
  color: white;
  height: 100%;
  width: 100%;
  padding: 2%;
  overflow: hidden;
}
.clcHeader {
  text-align: center;
  font-family: 'Sulphur Point', sans-serif;
}
.noM {
  margin-left: 0px !important;
  padding-left: 0px !important;
  margin-right: 0px !important;
  padding-right: 0px !important;
}
.fRight {
  width: 100%;
  padding-inline-start: 0px !important;
}
.activeButton {
  border-color: '#FF3F8E' !important;
}
.activeButton:focus {
  box-shadow: none !important;
}
.nonActiveButton {
  border: none !important;
}
.noBreakWord p {
  word-wrap: normal;
}
.centeredGroup {
  display: block;
  width: 100%;
  text-align: center;
  margin: 0 auto;
}
.centeredGroup button {
  margin: 10px;
}
