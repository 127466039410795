@import url(https://fonts.googleapis.com/css?family=Abel|Raleway|Raleway+Dot|Asap|Barlow|Glegoo|Julius+Sans+One|Lato|Muli|Poiret+One|Sulphur+Point|Turret+Road&display=swap);
@import url(https://fonts.googleapis.com/css?family=Raleway+Dots&display=swap);
@import url(https://fonts.googleapis.com/css?family=Raleway&display=swap);
@import url(https://fonts.googleapis.com/css?family=Abel|Asap|Barlow|Glegoo|Julius+Sans+One|Lato|Muli|Poiret+One|Sulphur+Point|Turret+Road&display=swap);
@import url(https://fonts.googleapis.com/css?family=Abel|Asap|Raleway+Dots|Roboto+Mono|Raleway|Barlow|Glegoo|Julius+Sans+One|Lato|Muli|Poiret+One|Sulphur+Point|Turret+Road&display=swap);
@import url(https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700,800,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Abel|Asap|Raleway+Dots|Raleway|Barlow|Glegoo|Julius+Sans+One|Lato|Muli|Poiret+One|Sulphur+Point|Turret+Road&display=swap);
@import url(https://fonts.googleapis.com/css?family=Abel|Asap|Raleway+Dots|Roboto+Mono|Raleway|Barlow|Glegoo|Julius+Sans+One|Lato|Muli|Poiret+One|Sulphur+Point|Turret+Road&display=swap);
@import url(https://fonts.googleapis.com/css?family=Raleway+Dots&display=swap);
@import url(https://fonts.googleapis.com/css?family=Raleway&display=swap);
@import url(https://fonts.googleapis.com/css?family=Abel|Asap|Barlow|Glegoo|Julius+Sans+One|Lato|Muli|Poiret+One|Sulphur+Point|Turret+Road&display=swap);
@import url(https://fonts.googleapis.com/css?family=Abel|Raleway|Raleway+Dot|Asap|Barlow|Glegoo|Julius+Sans+One|Lato|Muli|Poiret+One|Sulphur+Point|Turret+Road&display=swap);
@import url(https://fonts.googleapis.com/css?family=Abel|Raleway|Raleway+Dot|Asap|Barlow|Glegoo|Julius+Sans+One|Lato|Muli|Poiret+One|Sulphur+Point|Turret+Road&display=swap);
@import url(https://fonts.googleapis.com/css?family=Indie+Flower|Lobster+Two|Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css?family=Abel|Asap|Raleway+Dots|Roboto+Mono|Raleway|Barlow|Glegoo|Julius+Sans+One|Lato|Muli|Poiret+One|Sulphur+Point|Turret+Road&display=swap);
body,
html {
  margin: 0;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  overflow: scroll;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  background: #ff0000;
}

.global_particle__DYR8C {
  position: fixed !important;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.global_resumeButton__30V2O {
  border-color: #2e55c1;
}

.global_resumeButton__30V2O:hover {
  border-color: #2e55c1;
  background: #6666666b;
  color: aliceblue;
}

.global_aboutMain__3w2mc {
  min-height: 95vh;
}

.global_aboutHeader__NB9w6 {
  background-color: #090909;
}

.global_aboutFooter__zmwm- {
  min-height: 5vh;
}

.global_both__3qFLM {
  text-align: center;
  font-family: 'Roboto Mono', monospace;
  color: aliceblue;
  margin: 0 auto;
}

.global_both_icon__1ccYG {
  font-size: 10vw;
  color: aliceblue;
}

.global_both_label__3vI2K {
  font-size: 20px;
  color: aliceblue;
}

.global_hov__2xlP7:hover .global_both__3qFLM,
.global_hov__2xlP7:hover .global_both_icon__1ccYG,
.global_hov__2xlP7:hover .global_both_label__3vI2K {
  color: #ff3f8e !important;
  cursor: pointer;
  text-decoration: none;
}

@media screen and (min-width: 1000px) {
  .global_both_icon__1ccYG {
    font-size: 100px;
  }
}

.home_AppHeader__2dZ-L {
  background-color: #090909;
  font-size: calc(10px + 2vmin);
  color: white;
}
.home_AppMain__EZ9qk {
  min-height: 95vh;
}
.home_AppFooter__ogq1A {
  min-height: 5vh;
}
.home_homeLeft__2QNOH {
  height: 100%;
}
.home_homeLeftMain__3P3Lz {
  height: 100%;
  text-align: center;
}
.home_homeLeftMainCol__1h1_- {
  margin: auto;
  display: block;
}
.home_firstLine__xpry3 {
  font-size: 4.5rem;
  font-family: 'Sulphur Point', sans-serif;
}
.home_secondLine__Kp2pV {
  font-size: 3rem;
  font-family: 'Julius Sans One', sans-serif;
}
.home_minH__36uFi {
  min-height: 1rem;
  margin-bottom: 0%;
}
.home_homeRight__3QwF6 {
  height: 100%;
}
.home_homeRightMain__1eR2S {
  height: 100%;
}
.home_homeRightMainImg__2tcs8 {
  max-height: 65vh;
  margin: auto;
  display: block;
  max-width: 100%;
  width: auto;
  height: auto;
  border-radius: 15px;
}
.home_homeRightFooter__1sKlI {
  width: 100%;
}

.b_linkButton__1ObqK {
  color: aliceblue;
}

.b_linkButton__1ObqK:hover {
  color: aliceblue !important;
  background: #6666666b;
}

.social_socialIcons__1ZxXz {
  text-align: center;
}

.social_git__3DdL2,
.social_linkedin__1WNJs {
  margin: 2%;
  color: white;
}

.social_git__3DdL2:hover {
  color: #ff3f8e;
}

.social_linkedin__1WNJs:hover {
  color: #2e55c1;
}

.footer_footer__1lZIc {
  height: 100%;
  text-align: center;
  font-size: medium;
  color: rgb(202, 200, 200);
}

.about_aboutHeader__NMz3J {
  background-color: #090909;
}
.about_aboutFooter__1eYhS {
  min-height: 5vh;
}
.about_printIconContainer__1EPa8:before {
  top: 50px;
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
}
.about_printIconContainer__1EPa8 {
  text-align: center;
}
.about_printIconContainer__1EPa8:after {
  pointer-events: none;
}
.about_printIcon__1uyBX {
  color: aliceblue;
  cursor: pointer;
  font-size: 40px !important;
}
.about_printIcon__1uyBX:hover {
  color: #ff3f8e !important;
  cursor: pointer;
  text-decoration: none;
}
.about_clcMain__29iyv {
  color: white;
  height: 100%;
  width: 100%;
  padding: 2%;
  overflow: hidden;
}
.about_clcContainer__2CYp- {
  padding: 2%;
}
.about_clcHeader__AK36R {
  text-align: center;
  font-family: 'Sulphur Point', sans-serif;
}
.about_aboutBtnContainer__1YFaE {
  width: 100%;
}

@media only screen and (max-width: 1200px) {
  .about_clcMain__29iyv {
    padding-top: 0%;
  }
}

.resume_resumeContentPage__1prHe {
  margin: 0 0.25in;
}
.resume_centered__30DLM {
  text-align: center;
}
.resume_makeBold__1DJuI {
  font-weight: bold;
}
.resume_wrp__2k_bw {
  width: 100%;
  font-size: 0;
}
.resume_wrp__2k_bw span,
.resume_wrp__2k_bw div,
.resume_wrp__2k_bw td {
  background-color: #090909;
  color: aliceblue;
}
.resume_wrp__2k_bw tr td:nth-child(2) {
  text-align: right;
  padding-left: 10px;
}
.resume_smallerButton__LCt4Q {
  height: 80%;
  line-height: 60%;
  font-size: 40%;
}
.resume_titleSize__3iNoV {
  font-size: 14pt;
  font-weight: bold;
}
.resume_sideTitle__wKvEG {
  font-size: 14pt;
  font-weight: bold;
  background-color: #090909;
  color: aliceblue;
  margin-bottom: 0;
}
.resume_sideDesc__NZnRB {
  padding-left: 0;
}
.resume_sideDesc__NZnRB li {
  font-size: 12pt;
  list-style-type: none;
  background-color: #090909;
  color: #a8adb2;
  margin-left: 10px;
  padding-left: 15px;
  text-indent: -15px;
  text-align: left;
}
.resume_subtitleSize__8cpjZ {
  font-size: 14pt;
}
.resume_fitSize__AmWz3 {
  font-size: 14pt;
}
.resume_detailItems__1pQ8A {
  text-align: left;
  width: calc(100% - 2em);
}
.resume_detailItems__1pQ8A li {
  text-align: left;
  padding-left: 1em;
  text-indent: -1em;
}
.resume_detailItems__1pQ8A li:before,
.resume_sideDesc__NZnRB li:before {
  content: '»   ';
  color: #a8adb2;
}
.resume_maskBackground__seAP2 {
  background-color: #090909;
  color: aliceblue;
}
li .resume_maskBackground__seAP2 {
  color: #a8adb2;
}
.resume_noPrint__zVr_N {
  display: block;
}
.resume_printOnly__2Uzpe {
  display: none;
}
/* .qr {
  display: none;
} */
.resume_magentaButton__1I2bi {
  border-color: #ff3f8e !important;
}
.resume_activeStyleFilter__1HtRJ {
  border-color: aliceblue !important;
}
.resume_activeStyleFilter__1HtRJ:focus {
  box-shadow: none !important;
}
.resume_resumeFilterButtons__2n5Mk {
  display: inline;
}
.resume_resumeFilterButtons__2n5Mk {
  float: right;
}
.resume_resumeFilterButtons__2n5Mk Button {
  margin: 0 5px;
  font-size: 20px;
  margin-top: 15px;
}
.resume_sideBarMessage__34E58 {
  margin-top: 200px;
  color: aliceblue;
  font-size: 300%;
  text-align: center;
}

.resume_softSkillBubble__1aMhq {
  padding: 2px;
  border-radius: 4px;
  margin: 5px !important;
  display: inline-block;
  text-align: center;
  font-size: 12px;
  -webkit-flex-grow: 1;
          flex-grow: 1;
}
.resume_softSkillBubble__1aMhq:hover {
  background-color: #ff3f8e;
  cursor: pointer;
}
.resume_softSkillContainer__SXu3p {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.resume_skillTip__2uieD [class$='inner'] {
  background-color: red;
  border: 1px solid red;
}

.resume_skillTip__2uieD [class$='arrow'] {
  border-top: 5px solid red !important;
}

@media screen and (max-width: 700px) {
  .resume_display-1__kkl_H {
    font-size: 75px;
  }
  .resume_resumeFilterButtons__2n5Mk {
    display: none;
  }
}

@media screen and (max-width: 575px) {
  .resume_sideBarMessage__34E58 {
    display: none;
  }
}

@media screen and (max-width: 500px) {
  .resume_display-1__kkl_H {
    font-size: 50px;
  }
}

@media print {
  .resume_chartContainer__3NaQY {
    max-width: 100px;
  }
  .resume_resumeContentPage__1prHe {
    margin: 0 0.75in;
  }
  .resume_detailItems__1pQ8A {
    font-size: 14pt;
  }
  h1 {
    margin-top: 0px;
    padding-top: 0px;
  }
  .resume_smallerH1__DGNNB {
    font-size: 2rem !important;
    color: blue;
  }
  h1,
  h3,
  h6 {
    line-height: 1;
  }
  .resume_display-1__kkl_H {
    padding-top: 0.25in;
  }
  .resume_maskBackground__seAP2,
  .resume_wrp__2k_bw td {
    margin: 0;
    color: #000 !important;
    background-color: #fff;
  }
  .resume_sideTitle__wKvEG,
  .resume_sideDesc__NZnRB li,
  .resume_wrp__2k_bw span {
    background-color: #fff;
    color: #000 !important;
  }
  .resume_noPrint__zVr_N {
    display: none;
  }
  .resume_printOnly__2Uzpe {
    display: block;
  }
  .resume_qr__11heZ {
    display: block;
    float: right;
    /* margin-top: -70px;
    height: 3em; */
    /* margin-top: -50px;
    height: 2.5em; */
    margin-top: -40px;
    height: 2em;
  }
  .resume_qrSide__2btSv {
    display: block;
    margin-left: -6px;
    width: 90%;
  }
  .resume_mTopSmall__SNF48 {
    margin-top: 10px;
  }
  .resume_mTop__1TWS3 {
    margin-top: 20px;
  }
  .resume_resumeFilterButtons__2n5Mk {
    display: none;
  }
}

#softSkill-tooltip > .tooltip-inner {
  background-color: #2e55c1;
  color: aliceblue;
  border: 2px solid #ff3f8e;
}

#softSkill-tooltip > .tooltip-arrow {
  border-top: 5px solid #2e55c1;
}

.tooltip.show {
  opacity: 1;
}

:root {
  --overlay-color: rgba(31, 31, 31, 0.85);
}

.ham_menuWrap__32t8U {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  font-family: 'Poppins', sans-serif;
}

.ham_menuWrap__32t8U .ham_toggler__GEcsP {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  cursor: pointer;
  width: 50px;
  height: 50px;
  opacity: 0;
}

.ham_menuWrap__32t8U .ham_hamburger__1hBGI {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 80px;
  height: 80px;
  padding: 1rem;
  background: var(--primary-color);
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

/* Hamburger Line */
.ham_menuWrap__32t8U .ham_hamburger__1hBGI > div {
  position: relative;
  -webkit-flex: none;
          flex: none;
  width: 50%;
  height: 2px;
  background: #fff;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  transition: all 0.4s ease;
}

/* Hamburger Lines - Top & Bottom */
.ham_menuWrap__32t8U .ham_hamburger__1hBGI > div::before,
.ham_menuWrap__32t8U .ham_hamburger__1hBGI > div::after {
  content: '';
  position: absolute;
  z-index: 1;
  top: -10px;
  width: 100%;
  height: 2px;
  background: inherit;
}

/* Moves Line Down */
.ham_menuWrap__32t8U .ham_hamburger__1hBGI > div::after {
  top: 10px;
}

/* Toggler Animation */
.ham_menuWrap__32t8U .ham_toggler__GEcsP:checked + .ham_hamburger__1hBGI > div {
  -webkit-transform: rotate(135deg);
          transform: rotate(135deg);
}

/* Turns Lines Into X */
.ham_menuWrap__32t8U .ham_toggler__GEcsP:checked + .ham_hamburger__1hBGI > div:before,
.ham_menuWrap__32t8U .ham_toggler__GEcsP:checked + .ham_hamburger__1hBGI > div:after {
  top: 0;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

/* Rotate On Hover When Checked */
.ham_menuWrap__32t8U .ham_toggler__GEcsP:checked:hover + .ham_hamburger__1hBGI > div {
  -webkit-transform: rotate(225deg);
          transform: rotate(225deg);
}

/* Show Menu */
.ham_menuWrap__32t8U .ham_toggler__GEcsP:checked ~ .ham_menu__8Flu_ {
  visibility: visible;
}

.ham_menuWrap__32t8U .ham_toggler__GEcsP:checked ~ .ham_menu__8Flu_ > div {
  -webkit-transform: scale(1);
          transform: scale(1);
  transition-duration: var(--menu-speed);
}

.ham_menuWrap__32t8U .ham_toggler__GEcsP:checked ~ .ham_menu__8Flu_ > div > div {
  opacity: 1;
  transition: opacity 0.01s ease 0.01s;
}

.ham_menuWrap__32t8U .ham_menu__8Flu_ {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  overflow: hidden;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.ham_menuWrap__32t8U .ham_menu__8Flu_ > div {
  background: rgba(31, 31, 31, 0.85);
  background: var(--overlay-color);
  width: 200vw;
  height: 200vw;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.ham_menuWrap__32t8U .ham_menu__8Flu_ > div > div {
  text-align: center;
  max-width: 100vw;
  max-height: 100vh;
  opacity: 0;
}

ul {
  position: relative;
}

ul li {
  list-style: none;
  text-align: center;
}

ul li a {
  color: #fff;
  text-decoration: none;
  font-size: 1.7em;
  padding: 5px 20px;
  display: -webkit-inline-flex;
  display: inline-flex;
  font-weight: 700;
  transition: 0.5s;
}

ul:hover li a {
  color: rgba(255, 255, 255, 1);
}

ul li:hover a {
  font-size: 3em;
  color: #000;
  background: rgba(255, 255, 255, 1);
}

ul li a:before {
  content: '';
  position: absolute;
  font-size: 1.5em;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 500px;
}

.contact_contactHeader__3ir6T {
  background-color: #090909;
  color: white;
}
.contact_hamburgerMenu__2OWhB {
  min-height: 7vh;
}
.contact_contactMain__2bA6R {
  min-height: 88vh;
}
.contact_contactLeft__nMJ-j {
  margin: auto;
  display: block;
}
.contact_contactRight__3E20X {
  margin: auto;
  display: block;
}
.contact_contactFooter__1xnfe {
  min-height: 5vh;
}
.contact_contactText__2g2Fp {
  text-align: center;
}
.contact_pHeading1__133bw {
  font-size: 4rem;
  font-family: 'Sulphur Point', sans-serif;
  color: #ff3f8e;
}
.contact_pHeading2__FnPar {
  font-size: 0.98rem;
  padding: 2.5%;
  font-family: 'Roboto Mono', monospace;
}
.contact_contactLeftFooter__1HDDa {
  width: 100%;
  font-size: 1.7rem;
}
.contact_contactForm__IDTp6 {
  text-align: center;
  padding-bottom: 8%;
}
.contact_contactFormHeader__2TUIi {
  font-size: 2.5rem;
  padding: 1%;
  font-family: 'Raleway', sans-serif;
  color: #04c2c9;
}
.contact_contactInput__2kB7j {
  padding-left: 10%;
  padding-right: 10%;
}
.contact_contactInputText__wm2fm {
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: 1.5rem;
  border-radius: 5%;
  text-align: center;
  background: transparent;
  color: white;
  min-height: 6.5vh;
  border-color: #2e55c1;
}
.contact_contactSuccessModalBody__Ww0iA {
  text-align: center;
  background: #090909;
  color: aliceblue;
}
.contact_contactEmailTextBtn__3W_tb {
  border-color: #2e55c1;
}
.contact_contactEmailTextBtn__3W_tb:hover {
  background: #666666;
  color: aliceblue;
}
.contact_successCheck__1_E6L {
  font-size: 8em;
  color: #ff3f8e;
  margin-bottom: 20px;
}

.loading_loadingHeader__2_IoY {
  background-color: #000000;
  background-image: url('https://nathanbirch.one/images/background.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: white;
  text-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  z-index: 1;
}

.projects_cardDisclaimer__-Vy_E {
  background: rgba(255, 255, 255, 0.1);
  color: white;
  /* border: 1px solid white; */
  border-radius: 10px;
  text-align: right;
  margin: 3em;
  width: 50%;
  margin: 3% 25%;
  line-height: 3em;
  height: 50px;
  vertical-align: middle;
  text-align: center;
}
.projects_mainRow__1rsK4 {
  font-family: 'Sulphur Point', sans-serif;
  margin-top: 50px;
}
.projects_cont__3QY7t {
  padding: 0 3%;
}
.projects_cardCol__3T87v {
  padding: 0.5%;
}
.projects_cardMain__34MaO {
  background: rgba(255, 255, 255, 0.1);
  color: white;
  border-color: white;
  text-align: center;
  margin: 0 1em;
  display: block;
  padding: 10px;
}
.projects_cardMain__34MaO h2,
.projects_cardMain__34MaO footer,
.projects_cardMain__34MaO img {
  text-align: center;
}
.projects_cardMain__34MaO img {
  display: block;
  margin: 0 auto;
}
.projects_cardMain__34MaO:hover {
  transition: all 0.2s ease-out;
  top: -4px;
  background: rgba(255, 255, 255, 0.15);
  box-shadow: 0px 4px 35px rgb(255, 255, 255);
}
.projects_portImg__2CwqN {
  padding: 5px;
  display: inline-block;
  min-width: 230px;
  min-height: 95px;
  width: auto;
  height: auto;
  border-radius: 10px;
}

.personal_clcMain__3i-f6 {
  color: white;
  height: 100%;
  width: 100%;
  padding: 2%;
  overflow: hidden;
}
.personal_clcHeader__3sTyF {
  text-align: center;
  font-family: 'Sulphur Point', sans-serif;
}
.personal_noM__2aP-W {
  margin-left: 0px !important;
  padding-left: 0px !important;
  margin-right: 0px !important;
  padding-right: 0px !important;
}
.personal_fRight__17QPP {
  width: 100%;
  -webkit-padding-start: 0px !important;
          padding-inline-start: 0px !important;
}
.personal_activeButton__2pBM3 {
  border-color: '#FF3F8E' !important;
}
.personal_activeButton__2pBM3:focus {
  box-shadow: none !important;
}
.personal_nonActiveButton__2M3Kx {
  border: none !important;
}
.personal_noBreakWord__3IfNU p {
  word-wrap: normal;
}
.personal_centeredGroup__3Tsfa {
  display: block;
  width: 100%;
  text-align: center;
  margin: 0 auto;
}
.personal_centeredGroup__3Tsfa button {
  margin: 10px;
}

