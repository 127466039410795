.aboutHeader {
  background-color: #090909;
}
.aboutFooter {
  min-height: 5vh;
}
.printIconContainer:before {
  top: 50px;
  transform: translateY(100%);
}
.printIconContainer {
  text-align: center;
}
.printIconContainer:after {
  pointer-events: none;
}
.printIcon {
  color: aliceblue;
  cursor: pointer;
  font-size: 40px !important;
}
.printIcon:hover {
  color: #ff3f8e !important;
  cursor: pointer;
  text-decoration: none;
}
.clcMain {
  color: white;
  height: 100%;
  width: 100%;
  padding: 2%;
  overflow: hidden;
}
.clcContainer {
  padding: 2%;
}
.clcHeader {
  text-align: center;
  font-family: 'Sulphur Point', sans-serif;
}
.aboutBtnContainer {
  width: 100%;
}

@media only screen and (max-width: 1200px) {
  .clcMain {
    padding-top: 0%;
  }
}
