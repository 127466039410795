@import url('https://fonts.googleapis.com/css?family=Abel|Asap|Raleway+Dots|Raleway|Barlow|Glegoo|Julius+Sans+One|Lato|Muli|Poiret+One|Sulphur+Point|Turret+Road&display=swap');
@import url('https://fonts.googleapis.com/css?family=Abel|Asap|Raleway+Dots|Roboto+Mono|Raleway|Barlow|Glegoo|Julius+Sans+One|Lato|Muli|Poiret+One|Sulphur+Point|Turret+Road&display=swap');
@import url('https://fonts.googleapis.com/css?family=Raleway+Dots&display=swap');
@import url('https://fonts.googleapis.com/css?family=Raleway&display=swap');
@import url('https://fonts.googleapis.com/css?family=Abel|Asap|Barlow|Glegoo|Julius+Sans+One|Lato|Muli|Poiret+One|Sulphur+Point|Turret+Road&display=swap');

.contactHeader {
  background-color: #090909;
  color: white;
}
.hamburgerMenu {
  min-height: 7vh;
}
.contactMain {
  min-height: 88vh;
}
.contactLeft {
  margin: auto;
  display: block;
}
.contactRight {
  margin: auto;
  display: block;
}
.contactFooter {
  min-height: 5vh;
}
.contactText {
  text-align: center;
}
.pHeading1 {
  font-size: 4rem;
  font-family: 'Sulphur Point', sans-serif;
  color: #ff3f8e;
}
.pHeading2 {
  font-size: 0.98rem;
  padding: 2.5%;
  font-family: 'Roboto Mono', monospace;
}
.contactLeftFooter {
  width: 100%;
  font-size: 1.7rem;
}
.contactForm {
  text-align: center;
  padding-bottom: 8%;
}
.contactFormHeader {
  font-size: 2.5rem;
  padding: 1%;
  font-family: 'Raleway', sans-serif;
  color: #04c2c9;
}
.contactInput {
  padding-left: 10%;
  padding-right: 10%;
}
.contactInputText {
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: 1.5rem;
  border-radius: 5%;
  text-align: center;
  background: transparent;
  color: white;
  min-height: 6.5vh;
  border-color: #2e55c1;
}
.contactSuccessModalBody {
  text-align: center;
  background: #090909;
  color: aliceblue;
}
.contactEmailTextBtn {
  border-color: #2e55c1;
}
.contactEmailTextBtn:hover {
  background: #666666;
  color: aliceblue;
}
.successCheck {
  font-size: 8em;
  color: #ff3f8e;
  margin-bottom: 20px;
}
