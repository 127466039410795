@import url('https://fonts.googleapis.com/css?family=Abel|Raleway|Raleway+Dot|Asap|Barlow|Glegoo|Julius+Sans+One|Lato|Muli|Poiret+One|Sulphur+Point|Turret+Road&display=swap');
.AppHeader {
  background-color: #090909;
  font-size: calc(10px + 2vmin);
  color: white;
}
.AppMain {
  min-height: 95vh;
}
.AppFooter {
  min-height: 5vh;
}
.homeLeft {
  height: 100%;
}
.homeLeftMain {
  height: 100%;
  text-align: center;
}
.homeLeftMainCol {
  margin: auto;
  display: block;
}
.firstLine {
  font-size: 4.5rem;
  font-family: 'Sulphur Point', sans-serif;
}
.secondLine {
  font-size: 3rem;
  font-family: 'Julius Sans One', sans-serif;
}
.minH {
  min-height: 1rem;
  margin-bottom: 0%;
}
.homeRight {
  height: 100%;
}
.homeRightMain {
  height: 100%;
}
.homeRightMainImg {
  max-height: 65vh;
  margin: auto;
  display: block;
  max-width: 100%;
  width: auto;
  height: auto;
  border-radius: 15px;
}
.homeRightFooter {
  width: 100%;
}
