.socialIcons {
  text-align: center;
}

.git,
.linkedin {
  margin: 2%;
  color: white;
}

.git:hover {
  color: #ff3f8e;
}

.linkedin:hover {
  color: #2e55c1;
}
