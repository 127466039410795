.resumeContentPage {
  margin: 0 0.25in;
}
.centered {
  text-align: center;
}
.makeBold {
  font-weight: bold;
}
.wrp {
  width: 100%;
  font-size: 0;
}
.wrp span,
.wrp div,
.wrp td {
  background-color: #090909;
  color: aliceblue;
}
.wrp tr td:nth-child(2) {
  text-align: right;
  padding-left: 10px;
}
.smallerButton {
  height: 80%;
  line-height: 60%;
  font-size: 40%;
}
.titleSize {
  font-size: 14pt;
  font-weight: bold;
}
.sideTitle {
  font-size: 14pt;
  font-weight: bold;
  background-color: #090909;
  color: aliceblue;
  margin-bottom: 0;
}
.sideDesc {
  padding-left: 0;
}
.sideDesc li {
  font-size: 12pt;
  list-style-type: none;
  background-color: #090909;
  color: #a8adb2;
  margin-left: 10px;
  padding-left: 15px;
  text-indent: -15px;
  text-align: left;
}
.subtitleSize {
  font-size: 14pt;
}
.fitSize {
  font-size: 14pt;
}
.detailItems {
  text-align: left;
  width: calc(100% - 2em);
}
.detailItems li {
  text-align: left;
  padding-left: 1em;
  text-indent: -1em;
}
.detailItems li:before,
.sideDesc li:before {
  content: '»   ';
  color: #a8adb2;
}
.maskBackground {
  background-color: #090909;
  color: aliceblue;
}
li .maskBackground {
  color: #a8adb2;
}
.noPrint {
  display: block;
}
.printOnly {
  display: none;
}
/* .qr {
  display: none;
} */
.magentaButton {
  border-color: #ff3f8e !important;
}
.activeStyleFilter {
  border-color: aliceblue !important;
}
.activeStyleFilter:focus {
  box-shadow: none !important;
}
.resumeFilterButtons {
  display: inline;
}
.resumeFilterButtons {
  float: right;
}
.resumeFilterButtons Button {
  margin: 0 5px;
  font-size: 20px;
  margin-top: 15px;
}
.sideBarMessage {
  margin-top: 200px;
  color: aliceblue;
  font-size: 300%;
  text-align: center;
}

.softSkillBubble {
  padding: 2px;
  border-radius: 4px;
  margin: 5px !important;
  display: inline-block;
  text-align: center;
  font-size: 12px;
  flex-grow: 1;
}
.softSkillBubble:hover {
  background-color: #ff3f8e;
  cursor: pointer;
}
.softSkillContainer {
  display: flex;
  flex-wrap: wrap;
}

.skillTip [class$='inner'] {
  background-color: red;
  border: 1px solid red;
}

.skillTip [class$='arrow'] {
  border-top: 5px solid red !important;
}

@media screen and (max-width: 700px) {
  .display-1 {
    font-size: 75px;
  }
  .resumeFilterButtons {
    display: none;
  }
}

@media screen and (max-width: 575px) {
  .sideBarMessage {
    display: none;
  }
}

@media screen and (max-width: 500px) {
  .display-1 {
    font-size: 50px;
  }
}

@media print {
  .chartContainer {
    max-width: 100px;
  }
  .resumeContentPage {
    margin: 0 0.75in;
  }
  .detailItems {
    font-size: 14pt;
  }
  h1 {
    margin-top: 0px;
    padding-top: 0px;
  }
  .smallerH1 {
    font-size: 2rem !important;
    color: blue;
  }
  h1,
  h3,
  h6 {
    line-height: 1;
  }
  .display-1 {
    padding-top: 0.25in;
  }
  .maskBackground,
  .wrp td {
    margin: 0;
    color: #000 !important;
    background-color: #fff;
  }
  .sideTitle,
  .sideDesc li,
  .wrp span {
    background-color: #fff;
    color: #000 !important;
  }
  .noPrint {
    display: none;
  }
  .printOnly {
    display: block;
  }
  .qr {
    display: block;
    float: right;
    /* margin-top: -70px;
    height: 3em; */
    /* margin-top: -50px;
    height: 2.5em; */
    margin-top: -40px;
    height: 2em;
  }
  .qrSide {
    display: block;
    margin-left: -6px;
    width: 90%;
  }
  .mTopSmall {
    margin-top: 10px;
  }
  .mTop {
    margin-top: 20px;
  }
  .resumeFilterButtons {
    display: none;
  }
}
