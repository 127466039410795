@import url('https://fonts.googleapis.com/css?family=Raleway+Dots&display=swap');
@import url('https://fonts.googleapis.com/css?family=Raleway&display=swap');
@import url('https://fonts.googleapis.com/css?family=Abel|Asap|Barlow|Glegoo|Julius+Sans+One|Lato|Muli|Poiret+One|Sulphur+Point|Turret+Road&display=swap');
@import url('https://fonts.googleapis.com/css?family=Abel|Asap|Raleway+Dots|Roboto+Mono|Raleway|Barlow|Glegoo|Julius+Sans+One|Lato|Muli|Poiret+One|Sulphur+Point|Turret+Road&display=swap');
@import url('https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700,800,900&display=swap');

:root {
  --overlay-color: rgba(31, 31, 31, 0.85);
}

.menuWrap {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  font-family: 'Poppins', sans-serif;
}

.menuWrap .toggler {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  cursor: pointer;
  width: 50px;
  height: 50px;
  opacity: 0;
}

.menuWrap .hamburger {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 80px;
  height: 80px;
  padding: 1rem;
  background: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Hamburger Line */
.menuWrap .hamburger > div {
  position: relative;
  flex: none;
  width: 50%;
  height: 2px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.4s ease;
}

/* Hamburger Lines - Top & Bottom */
.menuWrap .hamburger > div::before,
.menuWrap .hamburger > div::after {
  content: '';
  position: absolute;
  z-index: 1;
  top: -10px;
  width: 100%;
  height: 2px;
  background: inherit;
}

/* Moves Line Down */
.menuWrap .hamburger > div::after {
  top: 10px;
}

/* Toggler Animation */
.menuWrap .toggler:checked + .hamburger > div {
  transform: rotate(135deg);
}

/* Turns Lines Into X */
.menuWrap .toggler:checked + .hamburger > div:before,
.menuWrap .toggler:checked + .hamburger > div:after {
  top: 0;
  transform: rotate(90deg);
}

/* Rotate On Hover When Checked */
.menuWrap .toggler:checked:hover + .hamburger > div {
  transform: rotate(225deg);
}

/* Show Menu */
.menuWrap .toggler:checked ~ .menu {
  visibility: visible;
}

.menuWrap .toggler:checked ~ .menu > div {
  transform: scale(1);
  transition-duration: var(--menu-speed);
}

.menuWrap .toggler:checked ~ .menu > div > div {
  opacity: 1;
  transition: opacity 0.01s ease 0.01s;
}

.menuWrap .menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menuWrap .menu > div {
  background: var(--overlay-color);
  width: 200vw;
  height: 200vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menuWrap .menu > div > div {
  text-align: center;
  max-width: 100vw;
  max-height: 100vh;
  opacity: 0;
}

ul {
  position: relative;
}

ul li {
  list-style: none;
  text-align: center;
}

ul li a {
  color: #fff;
  text-decoration: none;
  font-size: 1.7em;
  padding: 5px 20px;
  display: inline-flex;
  font-weight: 700;
  transition: 0.5s;
}

ul:hover li a {
  color: rgba(255, 255, 255, 1);
}

ul li:hover a {
  font-size: 3em;
  color: #000;
  background: rgba(255, 255, 255, 1);
}

ul li a:before {
  content: '';
  position: absolute;
  font-size: 1.5em;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 500px;
}
